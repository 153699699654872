import { jsx as _jsx } from "react/jsx-runtime";
import PromoSection from '../promosection';
import { PortableText } from '../richtext';
export const promoBlockColorMap = {
    blueberry50: 'blueberry',
    blueberry500: 'blueberry',
    cherry50: 'cherry',
    cherry500: 'cherry',
    neutral50: 'neutral',
    neutral700: 'neutral',
};
const Diveboard = ({ promoblock }) => {
    if (!promoblock.link) {
        return null;
    }
    return (_jsx("div", { className: "container", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-12", children: _jsx(PromoSection, { title: promoblock.tittel, color: promoBlockColorMap[promoblock.backgroundColor], imageAltText: promoblock.imageAltText, imageUrl: promoblock.imageUrl, linkText: promoblock.link.displayName, linkUrl: promoblock.link.url, mode: promoblock.darkBackground ? 'ondark' : 'onlight', size: 'large', children: promoblock.introduksjon && _jsx(PortableText, { value: promoblock.introduksjon }) }) }) }) }));
};
export default Diveboard;
