import { jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import { PromoBlockType } from '../types/entities';
import { promoBlockColorMap } from '../diveboard';
import PromoSection from '../promosection';
import PortableText from '../richtext';
import styles from './styles.module.scss';
const Promoboard = props => {
    const isLarge = props.promoBlock.type === PromoBlockType.diveboard;
    const backgroundColor = promoBlockColorMap[props.promoBlock.backgroundColor];
    const mode = props.promoBlock.darkBackground ? 'ondark' : 'onlight';
    const promoSection = (_jsx(PromoSection, { title: props.promoBlock.tittel, color: backgroundColor, imageAltText: props.promoBlock.imageAltText, imageCaption: props.promoBlock.imageCredit, imageType: props.promoBlock.imageType, imageUrl: props.promoBlock.imageUrl, linkText: props.promoBlock.link?.displayName, linkUrl: props.promoBlock.link?.url, mode: mode, reverse: props.reverse, size: isLarge ? 'large' : 'medium', className: styles.promoboard__panel, children: props.promoBlock.introduksjon && _jsx(PortableText, { value: props.promoBlock.introduksjon }) }));
    if (props.promoBlock.fullWidth) {
        return (_jsx("div", { className: cn(styles[`promoboard--${backgroundColor}-${mode}`], props.wrapperClassName), children: _jsx("div", { className: "container", children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-12", children: promoSection }) }) }) }));
    }
    return _jsx("div", { className: props.wrapperClassName, children: promoSection });
};
export default Promoboard;
