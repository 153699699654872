import { jsx as _jsx } from "react/jsx-runtime";
import { PromoBlockType } from '../types/entities';
import { uuid } from '@helsenorge/designsystem-react';
import { getPromoBlockGroups } from './helper';
import PromopanelGroup from './promopanel-group';
import Promoboard from '../promoboard';
import styles from './styles.module.scss';
const Promopanel = ({ promoblocks }) => {
    const promoBlockGroups = getPromoBlockGroups(promoblocks);
    return (_jsx("div", { className: styles.promopanel, "data-testid": "promopanel", children: promoBlockGroups.map(group => {
            if (group.type === PromoBlockType.promoboard) {
                return _jsx(PromopanelGroup, { group: group }, uuid());
            }
            else {
                return group.blocks.map((promoBlock) => {
                    if (promoBlock.fullWidth) {
                        return _jsx(Promoboard, { promoBlock: promoBlock }, uuid());
                    }
                    return (_jsx("div", { className: 'container', children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-12", children: _jsx(Promoboard, { promoBlock: promoBlock }) }) }) }, uuid()));
                });
            }
        }) }));
};
export default Promopanel;
