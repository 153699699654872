import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import { ImageType } from '../types/entities';
import Button from '@helsenorge/designsystem-react/components/Button';
import Title from '@helsenorge/designsystem-react/components/Title';
import { useUuid } from '@helsenorge/designsystem-react';
import styles from './styles.module.scss';
const PromoSection = props => {
    const { titleHtmlMarkup = 'h2', color = 'blueberry', mode = 'onlight', imageType = ImageType.photo, size = 'medium' } = props;
    const captionId = useUuid();
    const classes = cn(styles.promosection, styles[`promosection--${size}`], styles[`promosection--${color}-${mode}`], props.hoverMode === 'cover' && styles['promosection--cover'], props.reverse && styles['promosection--reverse'], props.imageUrl && styles[`promosection--${imageType}`], props.className);
    const imageClasses = cn(styles.promosection__image, styles[`promosection__image--${imageType}`], props.reverse && styles['promosection__image--reverse']);
    const linkClasses = cn(props.hoverMode === 'cover' && styles['promosection__link--cover']);
    const contentClasses = cn(styles.promosection__content);
    return (_jsxs("div", { className: classes, children: [props.imageUrl && (_jsx("img", { src: props.imageUrl, alt: props.imageAltText, className: imageClasses, "aria-describedby": props.imageCaption ? captionId : undefined })), _jsxs("div", { className: contentClasses, children: [_jsx(Title, { htmlMarkup: titleHtmlMarkup, appearance: 'title3', children: props.title }), props.description && _jsx("p", { className: styles.promosection__description, children: props.description }), props.children, props.linkUrl && props.linkText && (_jsx("p", { className: styles['promosection__link-wrapper'], children: _jsx(Button, { variant: "borderless", className: linkClasses, mode: mode, htmlMarkup: "a", href: props.linkUrl, arrow: "accessibility-character", children: props.linkText }) })), props.imageCaption && (_jsx("p", { id: captionId, className: styles.promosection__caption, children: props.imageCaption }))] })] }));
};
export default PromoSection;
