import { jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import { uuid } from '@helsenorge/designsystem-react';
import { promoBlockColorMap } from '../diveboard';
import Promoboard from '../promoboard';
import styles from './promopanel-group.module.scss';
const PromopanelGroup = props => {
    const block1 = props.group.blocks[0];
    const block2 = props.group.blocks[1];
    const color1 = promoBlockColorMap[block1.backgroundColor];
    const color2 = promoBlockColorMap[block2.backgroundColor];
    const mode1 = block1.darkBackground ? 'ondark' : 'onlight';
    const mode2 = block2.darkBackground ? 'ondark' : 'onlight';
    return (_jsx("div", { className: styles[`promopanel-group--${color1}-${mode1}-to-${color2}-${mode2}`], children: _jsx("div", { className: styles['promopanel-group__container'], children: _jsx("div", { className: styles['promopanel-group__row'], children: props.group.blocks.map((promoBlock, blockIndex) => {
                    const isPreviousNotFullWidth = blockIndex > 0 && !props.group.blocks[blockIndex - 1].fullWidth;
                    const reverse = !promoBlock.fullWidth && isPreviousNotFullWidth;
                    const backgroundColor = promoBlockColorMap[promoBlock.backgroundColor];
                    const mode = promoBlock.darkBackground ? 'ondark' : 'onlight';
                    return (_jsx("div", { className: cn(styles['promopanel-group__col'], styles[`promopanel-group__col--${backgroundColor}-${mode}`]), children: _jsx(Promoboard, { promoBlock: promoBlock, reverse: reverse, wrapperClassName: styles['promopanel-group__item'] }) }, uuid()));
                }) }) }) }));
};
export default PromopanelGroup;
