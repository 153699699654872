/**
 * Grupper promoblokker to og to. "promoboard" er i samme gruppe om de
 * kommer etter hverandre. "diveboard" er alltid alene i en gruppe.
 * @param blocks Liste med blokker
 * @returns Grupperte blokker
 */
export const getPromoBlockGroups = (blocks) => {
    let groups = [];
    if (blocks.length > 0) {
        let currentGroupIndex = 0;
        let currentGroupType = blocks[0].type;
        blocks.forEach((block, index) => {
            if (index === 0) {
                groups = groups.concat({ type: currentGroupType, blocks: [block] });
            }
            else if (currentGroupType !== block.type || groups[currentGroupIndex].blocks.length === 2) {
                // Start ny gruppe hvis blokken er av ny type, eller forrige gruppe allerede har to blokker
                currentGroupIndex += 1;
                currentGroupType = block.type;
                groups = groups.concat({ type: currentGroupType, blocks: [block] });
            }
            else {
                groups[currentGroupIndex].blocks = groups[currentGroupIndex].blocks.concat(block);
            }
        });
    }
    // Grupper med bare én blokk vises alltid som "diveboard"
    return groups.map(group => (group.blocks.length === 1 ? { type: 'diveboard', blocks: group.blocks } : group));
};
